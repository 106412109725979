<template>
  <q-layout view="hHh lpR fFf">
    <q-header class="bg-primary text-white">
      <q-toolbar>
        <q-toolbar-title>
          <div class="row items-center q-gutter-x-md">
            <div class="col-auto">
              Live Display Admin
            </div>

            <div
              v-if="!isBasePath"
              class="col-auto"
            >
              <q-btn
                outline
                label="Retour"
                color="white"
                :loading="isLoading"
                @click="goBack"
              />
            </div>
          </div>
        </q-toolbar-title>

        <q-space />

        <q-btn
          v-if="!isAuthenticated"
          label="Se connecter"
          color="white"
          text-color="primary"
          :loading="isLoading"
          @click="login"
        />
        <q-btn
          v-else
          outline
          label="Se déconnecter"
          color="white"
          :loading="isLoading"
          @click="logout"
        />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
const authenticationStore = useAuthenticationStore()
const { isAuthenticated } = storeToRefs(authenticationStore)
const { signIn, signOut } = authenticationStore

const $q = useQuasar()
const route = useRoute()

const isLoading = ref<boolean>(false)

const isBasePath = computed<boolean>(() => {
  return ['/', '/enterprises'].includes(route.fullPath)
})

function refreshData () {
  window.setTimeout(() => {
    window.location.reload()
  }, 1000)
}

async function login () {
  isLoading.value = true

  try {
    const authUser = await signIn('google')

    if (!authUser) {
      throw new Error('Authentication info is empty')
    }

    if (!authUser.email) {
      throw new Error('Email is not available')
    }

    $q.notify({
      type: 'positive',
      message: 'Connecté avec succès !'
    })

    refreshData()
  } catch (err) {
    const { message } = err as Error

    $q.notify({
      type: 'negative',
      message: 'Impossible de se connecter.',
      caption: message,
      timeout: 0,
      actions: [
        {
          round: true,
          dense: true,
          icon: 'close',
          color: 'white'
        }
      ]
    })
  } finally {
    isLoading.value = false
  }
}

async function logout () {
  isLoading.value = true

  try {
    const isLogout = await signOut()

    if (!isLogout) {
      throw new Error('Logout unsuccessful')
    }

    $q.notify({
      type: 'positive',
      message: 'Déconnecté avec succès !'
    })

    refreshData()
  } catch (err) {
    const { message } = err as Error

    $q.notify({
      type: 'negative',
      message: 'Impossible de se déconnecter.',
      caption: message,
      timeout: 0,
      actions: [
        {
          round: true,
          dense: true,
          icon: 'close',
          color: 'white'
        }
      ]
    })
  } finally {
    isLoading.value = false
  }
}

function goBack () {
  const routeSegments = route.fullPath.split('/')
  const totalSegments = routeSegments.length

  if (totalSegments === 0) {
    return navigateTo('/')
  }

  routeSegments.pop()

  const backRoute = routeSegments.join('/')
  return navigateTo(backRoute)
}
</script>
